import React, { useState, useEffect } from 'react';
import { getDepositAddress } from '../services/api';
import { toast } from 'react-toastify';
import LoadingScreen from './LoadingScreen';

function Deposits() {
    const [depositAddress, setDepositAddress] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchDepositAddress();
    }, []);

    const fetchDepositAddress = async () => {
        setIsLoading(true);
        try {
            const data = await getDepositAddress();
            setDepositAddress(data.depositAddress);
        } catch (error) {
            console.error('Error fetching deposit address:', error);
            toast.error('Error fetching deposit address');
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <div className="bg-gray-100 rounded-lg p-6 space-y-4">
            <h2 className="text-2xl font-bold mb-4">Deposit USDT</h2>
            <p className="mb-2">Your USDT TRC20 Deposit Address:</p>
            <p className="bg-gray-200 p-2 rounded mb-4">{depositAddress}</p>
            <p className="text-sm text-gray-400">
                Send USDT to this address to deposit funds into your account. Only send USDT TRC20 tokens to this address.
            </p>
        </div>
    );
}

export default Deposits;