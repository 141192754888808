import { getToken, logout } from '../utils/auth';
import { toast } from 'react-toastify';

const API_URL = (process.env.REACT_APP_API_URL || 'http://localhost:3030') + '/api';

async function fetchWithAuth(endpoint, options = {}) {
    const token = getToken();
    const headers = {
        'Content-Type': 'application/json',
        ...options.headers,
    };

    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    try {
        const response = await fetch(`${API_URL}${endpoint}`, {
            ...options,
            headers,
        });

        if (!response.ok) {
            const errorData = await response.json();
            if (errorData.error.code === 401 && errorData.error.message === 'Token expired') {
                toast.error('Your session has expired. Please log in again.');
                logout();
                window.location.href = '/login';
                throw new Error('Session expired');
            }
            throw new Error(errorData.error.message || 'An error occurred');
        }

        return response.json();
    } catch (error) {
        console.error('Fetch error:', error);
        toast.error(error.message || 'An error occurred');
        throw error;
    }
}

async function fetchPublic(endpoint, options = {}) {
    const headers = {
        'Content-Type': 'application/json',
        ...options.headers,
    };

    const response = await fetch(`${API_URL}${endpoint}`, {
        ...options,
        headers,
    });

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'An error occurred');
    }

    return response.json();
}

export function login(username, password) {
    return fetchWithAuth('/auth/login', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
    });
}

export function register(username, email, password) {
    return fetchWithAuth('/auth/register', {
        method: 'POST',
        body: JSON.stringify({ username, email, password }),
    });
}

export function getUserInfo() {
    return fetchWithAuth('/user/info');
}

export function getTransactions(page = 1, limit = 10) {
    return fetchWithAuth(`/user/transactions?page=${page}&limit=${limit}`);
}

export function getDepositAddress() {
    return fetchWithAuth('/deposit-address');
}

export function makeWithdrawal(address, amount, currency) {
    return fetchWithAuth('/withdraw', {
        method: 'POST',
        body: JSON.stringify({ address, amount, currency }),
    });
}

export function updateBalance() {
    return fetchWithAuth('/user/update-balance', {
        method: 'POST',
    });
}

export function getDepositInfoByLinkId(linkId) {
    return fetchPublic(`/deposit-info/${linkId}`);
}

export function getActiveDeposits() {
    return fetchWithAuth('/active-deposits');
}