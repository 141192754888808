import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import Deposits from './components/Deposits';
import Withdrawals from './components/Withdrawals';
import Transactions from './components/Transactions';
import Login from './components/Login';
import Register from './components/Register';
import PaymentLink from './components/PaymentLink';
import Home from './components/Home';
import { isAuthenticated } from './utils/auth';

// Protected Route component
const ProtectedRoute = ({ children }) => {
    if (!isAuthenticated()) {
        return <Navigate to="/login" replace />;
    }
    return children;
};

function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const isPaymentLinkRoute = location.pathname.startsWith('/pay/');

    useEffect(() => {
        if (location.pathname === '/' && !isAuthenticated()) {
            navigate('/');
        }
    }, []);

    if (isPaymentLinkRoute) {
        return (
            <Routes>
                <Route path="/pay/:linkId" element={<PaymentLink />} />
            </Routes>
        );
    }

    return (
        <div className="flex flex-col min-h-screen bg-white text-black">
            <Header />
            <main className="flex-grow pt-16">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/dashboard" element={
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    } />
                    <Route path="/deposits" element={
                        <ProtectedRoute>
                            <Deposits />
                        </ProtectedRoute>
                    } />
                    <Route path="/withdrawals" element={
                        <ProtectedRoute>
                            <Withdrawals />
                        </ProtectedRoute>
                    } />
                    <Route path="/transactions" element={
                        <ProtectedRoute>
                            <Transactions />
                        </ProtectedRoute>
                    } />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </main>
            <ToastContainer position="bottom-right" />
        </div>
    );
}

export default App;