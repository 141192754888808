import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div className="bg-white text-black">
            <div className="container mx-auto px-6 py-16">
                <div className="text-center">
                    <h1 className="text-6xl font-bold mb-4">USDT Gateway</h1>
                    <p className="text-xl mb-8">Seamless USDT transactions for your business</p>
                    <Link to="/register" className="bg-black text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-800 transition duration-300">Get Started</Link>
                </div>

                <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="border border-gray-200 p-6 rounded-lg">
                        <h2 className="text-2xl font-bold mb-4">Easy Integration</h2>
                        <p>Integrate our USDT gateway into your application with minimal effort.</p>
                    </div>
                    <div className="border border-gray-200 p-6 rounded-lg">
                        <h2 className="text-2xl font-bold mb-4">Secure Transactions</h2>
                        <p>Your transactions are protected with industry-standard security measures.</p>
                    </div>
                    <div className="border border-gray-200 p-6 rounded-lg">
                        <h2 className="text-2xl font-bold mb-4">24/7 Support</h2>
                        <p>Our team is always available to assist you with any questions or issues.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;