import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isAuthenticated, logout } from '../utils/auth';
import { toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';

const Header = () => {
    const [authenticated, setAuthenticated] = useState(isAuthenticated());
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const menuRef = useRef(null);

    useEffect(() => {
        const currentAuthStatus = isAuthenticated();
        if (authenticated !== currentAuthStatus) {
            setAuthenticated(currentAuthStatus);
        }
    }, [location, authenticated]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleNavigation = (path) => {
        navigate(path);
    };

    const handleLogout = () => {
        logout();
        setAuthenticated(false);
        toast.success('Logged out successfully');
        navigate('/');
    };

    const menuVariants = {
        open: { opacity: 1, y: 0 },
        closed: { opacity: 0, y: "-100%" }
    };

    const docsUrl = `${process.env.REACT_APP_API_URL}/docs`;

    return (
        <header className="fixed w-full z-50 bg-black">
            <nav className="container mx-auto px-4 py-4">
                <div className="flex justify-between items-center">
                    <motion.span
                        className="text-2xl font-bold text-white hover:text-gray-300 transition-colors duration-300 cursor-pointer"
                        onClick={() => handleNavigation('/')}
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        USDT Gateway
                    </motion.span>
                    <div className="hidden lg:flex items-center space-x-4">
                        {authenticated ? (
                            <>
                                <NavButton onClick={() => handleNavigation('/dashboard')}>Dashboard</NavButton>
                                <NavButton onClick={() => handleNavigation('/deposits')}>Deposit</NavButton>
                                <NavButton onClick={() => handleNavigation('/withdrawals')}>Withdrawal</NavButton>
                                <NavButton onClick={() => handleNavigation('/transactions')}>Transactions</NavButton>
                                <NavButton onClick={() => window.open(docsUrl, '_blank')}>Docs</NavButton>
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    onClick={handleLogout}
                                    className="text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-black hover:bg-white transition-colors duration-300"
                                >
                                    Logout
                                </motion.button>
                            </>
                        ) : (
                            <>
                                <NavButton onClick={() => handleNavigation('/login')}>Login</NavButton>
                                <NavButton onClick={() => window.open(docsUrl, '_blank')}>Docs</NavButton>
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    onClick={() => handleNavigation('/register')}
                                    className="text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-black hover:bg-white transition-colors duration-300"
                                >
                                    Sign Up
                                </motion.button>
                            </>
                        )}
                    </div>
                    <button
                        className="lg:hidden text-white focus:outline-none"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                        </svg>
                    </button>
                </div>
                <AnimatePresence>
                    {isOpen && (
                        <motion.div
                            ref={menuRef}
                            className="lg:hidden fixed inset-0 bg-black bg-opacity-70 backdrop-blur-md z-40"
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={menuVariants}
                        >
                            <div className="flex justify-end p-4">
                                <button
                                    onClick={() => setIsOpen(false)}
                                    className="text-white focus:outline-none"
                                >
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </div>
                            <div className="pt-16 pb-3 space-y-1">
                                {authenticated ? (
                                    <>
                                        <MobileNavButton onClick={() => { handleNavigation('/dashboard'); setIsOpen(false); }}>Dashboard</MobileNavButton>
                                        <MobileNavButton onClick={() => { handleNavigation('/deposits'); setIsOpen(false); }}>Deposit</MobileNavButton>
                                        <MobileNavButton onClick={() => { handleNavigation('/withdrawals'); setIsOpen(false); }}>Withdrawal</MobileNavButton>
                                        <MobileNavButton onClick={() => { handleNavigation('/transactions'); setIsOpen(false); }}>Transactions</MobileNavButton>
                                        <MobileNavButton onClick={() => { window.open(docsUrl, '_blank'); setIsOpen(false); }}>Docs</MobileNavButton>
                                        <MobileNavButton
                                            onClick={() => {
                                                handleLogout();
                                                setIsOpen(false);
                                            }}
                                        >
                                            Logout
                                        </MobileNavButton>
                                    </>
                                ) : (
                                    <>
                                        <MobileNavButton onClick={() => { handleNavigation('/login'); setIsOpen(false); }}>Login</MobileNavButton>
                                        <MobileNavButton onClick={() => { window.open(docsUrl, '_blank'); setIsOpen(false); }}>Docs</MobileNavButton>
                                        <MobileNavButton onClick={() => { handleNavigation('/register'); setIsOpen(false); }}>Sign Up</MobileNavButton>
                                    </>
                                )}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </nav>
        </header>
    );
};

const NavButton = ({ onClick, children }) => (
    <motion.button
        className="text-sm text-white hover:text-gray-300 transition-colors duration-300"
        onClick={onClick}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
    >
        {children}
    </motion.button>
);

const MobileNavButton = ({ onClick, children }) => (
    <button
        className="block w-full text-left px-3 py-2 text-base font-medium text-white hover:text-gray-300 hover:bg-gray-700/20 rounded-md"
        onClick={onClick}
    >
        {children}
    </button>
);

export default Header;