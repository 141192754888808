import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/api';
import { setToken } from '../utils/auth';
import { toast } from 'react-toastify';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = await login(username, password);
            setToken(data.token);
            toast.success('Logged in successfully');
            navigate('/dashboard');
        } catch (error) {
            console.error('Error logging in:', error);
            toast.error('Error logging in. Please try again.');
        }
    };

    return (
        <div className="container mx-auto px-6 py-16">
            <div className="max-w-md mx-auto">
                <h2 className="text-3xl font-bold mb-6">Login</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="username" className="block mb-1">Username:</label>
                        <input
                            type="text"
                            id="username"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block mb-1">Password:</label>
                        <input
                            type="password"
                            id="password"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="w-full bg-black text-white py-2 px-4 rounded hover:bg-gray-800">
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Login;