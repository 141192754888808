import React, { useState, useEffect } from 'react';
import { makeWithdrawal, getActiveDeposits } from '../services/api';
import { toast } from 'react-toastify';
import LoadingScreen from './LoadingScreen';

function Withdrawals() {
    const [address, setAddress] = useState('');
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState('USDT');
    const [activeDeposits, setActiveDeposits] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchActiveDeposits();
    }, []);

    const fetchActiveDeposits = async () => {
        setIsLoading(true);
        try {
            const response = await getActiveDeposits();
            setActiveDeposits(response.activeDeposits);
        } catch (error) {
            console.error('Error fetching active deposits:', error);
            toast.error('Error fetching active deposits');
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <LoadingScreen />;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (currency === 'TRX' && activeDeposits > 0) {
            toast.error('TRX withdrawal not allowed while there are active deposits');
            return;
        }
        try {
            const response = await makeWithdrawal(address, amount, currency);
            toast.success(`Withdrawal successful. TXID: ${response.txid}`);
            setAddress('');
            setAmount('');
        } catch (error) {
            console.error('Error making withdrawal:', error);
            toast.error(error.message || 'Error making withdrawal. Please try again.');
        }
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h2 className="text-3xl font-bold mb-6">Withdraw</h2>
            {activeDeposits > 0 && (
                <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
                    <p className="font-bold">Warning</p>
                    <p>You have {activeDeposits} active deposit(s). TRX withdrawals are not allowed until all deposits are processed.</p>
                </div>
            )}
            <form onSubmit={handleSubmit} className="max-w-md mx-auto space-y-4">
                <div>
                    <label htmlFor="address" className="block mb-1">TRC20 Address:</label>
                    <input
                        type="text"
                        id="address"
                        className="w-full p-2 border border-gray-300 rounded"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="amount" className="block mb-1">Amount:</label>
                    <input
                        type="number"
                        id="amount"
                        className="w-full p-2 border border-gray-300 rounded"
                        step="0.000001"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="currency" className="block mb-1">Currency:</label>
                    <select
                        id="currency"
                        className="w-full p-2 border border-gray-300 rounded"
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                        required
                    >
                        <option value="USDT">USDT</option>
                        <option value="TRX" disabled={activeDeposits > 0}>TRX {activeDeposits > 0 ? '(Not available)' : ''}</option>
                    </select>
                </div>
                <button type="submit" className="w-full bg-black text-white py-2 px-4 rounded hover:bg-gray-800" disabled={currency === 'TRX' && activeDeposits > 0}>
                    Withdraw
                </button>
            </form>
        </div>
    );
}

export default Withdrawals;