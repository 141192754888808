import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { getDepositInfoByLinkId } from '../services/api';

function PaymentLink() {
    const [depositInfo, setDepositInfo] = useState(null);
    const [timeLeft, setTimeLeft] = useState(0);
    const [isCopied, setIsCopied] = useState(false);
    const { linkId } = useParams();
    const [status, setStatus] = useState('loading');

    useEffect(() => {
        const fetchDepositInfo = async () => {
            try {
                const data = await getDepositInfoByLinkId(linkId);
                if (data && data.address) {
                    setDepositInfo(data);
                    const currentTime = Date.now();
                    const expiryTime = new Date(data.expiryTime).getTime();

                    if (currentTime > expiryTime) {
                        setStatus('expired');
                    } else if (data.status === 'received') {
                        setStatus('received');
                    } else {
                        setStatus('pending');
                        setTimeLeft(Math.max(0, Math.floor((expiryTime - currentTime) / 1000)));
                    }
                } else {
                    setStatus('expired');
                }
            } catch (error) {
                console.error('Error fetching deposit info:', error);
                setStatus('expired');
            }
        };

        fetchDepositInfo();

        const pollInterval = setInterval(fetchDepositInfo, 10000); // Poll every 10 seconds

        return () => clearInterval(pollInterval);
    }, [linkId]);

    useEffect(() => {
        console.log('status changed to', status)
    }, [status])


    useEffect(() => {
        if (timeLeft <= 0 && status === 'pending') {
            return;
        }

        const timerId = setInterval(() => {
            setTimeLeft(prev => {
                if (prev <= 1) {
                    // Only set to expired when the countdown actually reaches zero
                    setStatus('expired');
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timerId);
    }, [timeLeft, status]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(depositInfo.address);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
    };

    if (status === 'loading') {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 p-4">
                <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
                    <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">Retrieving Payment Details</h2>
                    <div className="w-full h-full flex justify-center items-center mb-6">
                        <div className="relative w-24 h-24">
                            <div className="absolute top-0 left-0 w-full h-full border-4 border-blue-200 rounded-full animate-pulse"></div>
                            <div className="absolute top-0 left-0 w-full h-full border-t-4 border-blue-500 rounded-full animate-spin"></div>
                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                <svg className="w-12 h-12 text-blue-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                    <path d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <p className="text-center text-gray-700 mb-4">We're securely fetching your payment information. This process ensures the safety and accuracy of your transaction.</p>
                    <div className="flex justify-center">
                        <div className="inline-flex rounded-md shadow">
                            <div className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600">
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Processing
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (status === 'expired') {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 p-4">
                <div className="bg-white rounded-xl shadow-2xl p-8 max-w-md w-full text-center">
                    <div className="mb-6">
                        <svg className="mx-auto h-24 w-24 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                    </div>
                    <h2 className="text-4xl font-bold text-gray-800 mb-4">Link Expired</h2>
                    <p className="text-xl text-gray-600 mb-8">This deposit link has expired or is no longer valid.</p>
                    <div className="bg-red-100 border-l-4 border-red-500 p-4 mb-8">
                        <p className="text-red-700 font-medium">
                            For security reasons, expired links cannot be reactivated.
                        </p>
                    </div>
                    <p className="text-gray-600">
                        Please contact support if you have any questions or need assistance.
                    </p>
                </div>
            </div>
        );
    }

    if (status === 'received') {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-green-400 to-green-600 p-4">
                <div className="bg-white rounded-lg shadow-lg p-6 text-center">
                    <h2 className="text-3xl font-bold text-green-600">Deposit Successful!</h2>
                    <p className="mt-4 text-gray-600">Your deposit has been received and processed.</p>
                    <p className="mt-2 text-xl font-semibold">Amount: {depositInfo.amount} USDT</p>
                </div>
            </div>
        );
    }

    return (
        <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 p-4">
            <div className="w-full max-w-3xl bg-white rounded-xl shadow-2xl overflow-hidden">
                <div className="bg-blue-600 py-6 px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold text-white text-center">Deposit USDT</h2>
                </div>

                <div className="p-4 sm:p-6 lg:p-8 space-y-6">
                    <div className="flex flex-col sm:flex-row gap-6 items-center">
                        <div className="w-full sm:w-1/2 flex justify-center">
                            <div className="bg-white p-4 rounded-lg shadow-md">
                                <QRCode
                                    value={depositInfo.address}
                                    size={200}
                                    level="H"
                                    includeMargin={false}
                                />
                            </div>
                        </div>

                        <div className="w-full sm:w-1/2 space-y-4">
                            <div>
                                <p className="text-sm font-medium text-gray-500 mb-1">Address:</p>
                                <div className="flex items-center bg-gray-100 rounded-md overflow-hidden">
                                    <p className="flex-grow p-2 text-sm font-mono text-gray-800 truncate">{depositInfo.address}</p>
                                    <button
                                        onClick={copyToClipboard}
                                        className="p-2 bg-blue-500 text-white hover:bg-blue-600 transition-colors"
                                    >
                                        {isCopied ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                                                <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            </div>

                            <div>
                                <p className="text-sm font-medium text-gray-500 mb-1">Amount:</p>
                                <p className="text-3xl font-bold text-green-600">{depositInfo.amount} USDT</p>
                            </div>

                            <div>
                                <p className="text-sm font-medium text-gray-500 mb-1">Time left:</p>
                                <p className="text-2xl font-bold text-blue-600">
                                    {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-md">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-yellow-700">
                                    Send USDT to this address to complete your deposit. Only send USDT TRC20 tokens to this address.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentLink;