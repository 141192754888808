import React, { useState, useEffect } from 'react';
import { getTransactions } from '../services/api';
import { toast } from 'react-toastify';
import LoadingScreen from './LoadingScreen';
import { Link } from 'react-router-dom';

function Transactions() {
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchTransactions();
    }, [currentPage]);

    const fetchTransactions = async () => {
        setIsLoading(true);
        try {
            const response = await getTransactions(currentPage);
            setTransactions(response.transactions);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('Error fetching transactions:', error);
            toast.error('Error fetching transactions');
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <h2 className="text-3xl font-bold mb-6">Transactions</h2>
            <div className="overflow-x-auto">
                {/* Desktop view */}
                <table className="w-full hidden md:table">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="p-2 text-left">Type</th>
                            <th className="p-2 text-left">Amount</th>
                            <th className="p-2 text-left">Currency</th>
                            <th className="p-2 text-left">Fee</th>
                            <th className="p-2 text-left">Address</th>
                            <th className="p-2 text-left">TXID</th>
                            <th className="p-2 text-left">Status</th>
                            <th className="p-2 text-left">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.length > 0 ? transactions.map((tx) => (
                            <tr key={tx.id} className="border-b hover:bg-gray-50">
                                <td className="p-2">{tx.type}</td>
                                <td className="p-2">{parseFloat(tx.amount).toFixed(3)}</td>
                                <td className="p-2">{tx.currency}</td>
                                <td className="p-2">{parseFloat(tx.fee).toFixed(2)}</td>
                                <td className="p-2">{tx.address}</td>
                                <td className="p-2 text-blue-700">
                                    <Link to={`https://tronscan.org/#/transaction/${tx.txid}`} target="_blank" className="hover:underline">
                                        {tx.txid.substring(0, 5)}...{tx.txid.substring(tx.txid.length - 5)}
                                    </Link>
                                </td>
                                <td className="p-2">{tx.status}</td>
                                <td className="p-2">{new Date(tx.createdAt).toLocaleString()}</td>
                            </tr>
                        )) : (
                            <tr>
                                <td colSpan="8" className="p-2 text-center">No transactions found</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                {/* Mobile view */}
                <div className="md:hidden">
                    {transactions.length > 0 ? transactions.map((tx) => (
                        <div key={tx.id} className="bg-white shadow-md rounded-lg mb-4 p-4">
                            <div className="flex justify-between mb-2">
                                <span className="font-bold uppercase">{tx.type}</span>
                                <span className={`font-bold ${tx.type === 'deposit' ? 'text-green-600' : 'text-red-600'}`}>
                                    {parseFloat(tx.amount).toFixed(3)} {tx.currency}
                                </span>
                            </div>
                            <div className="text-sm text-gray-600 mb-2">
                                Fee: {parseFloat(tx.fee).toFixed(2)} {tx.currency}
                            </div>
                            <div className="text-sm text-gray-600 mb-2">
                                Address: {tx.address}
                            </div>
                            <div className="text-sm text-blue-700 mb-2">
                                <Link to={`https://tronscan.org/#/transaction/${tx.txid}`} target="_blank" className="hover:underline">
                                    TXID: {tx.txid.substring(0, 15)}...{tx.txid.substring(tx.txid.length - 15)}
                                </Link>
                            </div>
                            <div className="text-sm text-gray-600 mb-2">
                                Status: {tx.status}
                            </div>
                            <div className="text-sm text-gray-600">
                                Date: {new Date(tx.createdAt).toLocaleString()}
                            </div>
                        </div>
                    )) : (
                        <div className="text-center p-4">No transactions found</div>
                    )}
                </div>
            </div>
            <div className="mt-4 flex justify-between items-center">
                <button
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300"
                >
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                    className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300"
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default Transactions;