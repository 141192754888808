import React, { useState, useEffect } from 'react';
import { getUserInfo, updateBalance } from '../services/api';
import { toast } from 'react-toastify';
import LoadingScreen from './LoadingScreen';

function Dashboard() {
    const [userInfo, setUserInfo] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const fetchUserInfo = async () => {
        setIsLoading(true);
        try {
            const data = await getUserInfo();
            setUserInfo(data);
        } catch (error) {
            console.error('Error fetching user info:', error);
            toast.error('Error fetching user info');
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdateBalance = async () => {
        setIsUpdating(true);
        try {
            const updatedInfo = await updateBalance();
            setUserInfo(updatedInfo);
            toast.success('Balance updated successfully');
        } catch (error) {
            console.error('Error updating balance:', error);
            toast.error('Error updating balance');
        } finally {
            setIsUpdating(false);
        }
    };

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <div className="container mx-auto px-6 py-16">
            <h2 className="text-3xl font-bold mb-8 text-center">Welcome to Your Dashboard</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-white rounded-lg shadow-md p-6">
                    <h3 className="text-xl font-semibold mb-4">User Information</h3>
                    <div className="space-y-2">
                        <p><span className="font-semibold text-gray-600">Username:</span> {userInfo.username}</p>
                        <p><span className="font-semibold text-gray-600">Email:</span> {userInfo.email}</p>
                    </div>
                </div>
                <div className="bg-white rounded-lg shadow-md p-6">
                    <h3 className="text-xl font-semibold mb-4">Account Balance</h3>
                    <div className="space-y-4">
                        <div className="flex justify-between items-center">
                            <span className="font-semibold text-gray-600">USDT Balance:</span>
                            <span className="text-2xl font-bold">{userInfo.usdtBalance} USDT</span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="font-semibold text-gray-600">TRX Balance:</span>
                            <span className="text-2xl font-bold">{userInfo.trxBalance} TRX</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-8 text-center">
                <button
                    onClick={handleUpdateBalance}
                    disabled={isUpdating}
                    className="bg-blue-600 text-white py-3 px-6 rounded-full text-lg font-semibold hover:bg-blue-700 transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    {isUpdating ? 'Updating...' : 'Update Balance'}
                </button>
            </div>
        </div>
    );
}

export default Dashboard;