import { toast } from 'react-toastify';

export function setToken(token) {
    localStorage.setItem('token', token);
}

export function getToken() {
    return localStorage.getItem('token');
}

export function removeToken() {
    localStorage.removeItem('token');
}

export function isAuthenticated() {
    return !!getToken();
}

export function logout() {
    removeToken();
    toast.info('You have been logged out.');
    window.location.href = '/';
}