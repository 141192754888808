import React from 'react';

function LoadingScreen() {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
            <div className="text-center">
                <div className="relative w-24 h-24 mx-auto mb-8">
                    <div className="absolute top-0 left-0 w-full h-full border-4 border-blue-200 rounded-full animate-pulse"></div>
                    <div className="absolute top-0 left-0 w-full h-full border-t-4 border-blue-600 rounded-full animate-spin"></div>
                </div>
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">Loading Dashboard</h2>
                <p className="text-gray-600 mb-6">Please wait while we fetch your data...</p>
                <div className="w-64 h-2 bg-gray-200 rounded-full mx-auto">
                    <div className="w-1/2 h-full bg-blue-600 rounded-full animate-pulse"></div>
                </div>
            </div>
            <div className="mt-12 text-center">
                <p className="text-sm text-gray-500">Optimizing your experience</p>
                <div className="flex justify-center mt-2 space-x-2">
                    <div className="w-2 h-2 bg-blue-600 rounded-full animate-bounce"></div>
                    <div className="w-2 h-2 bg-blue-600 rounded-full animate-bounce" style={{animationDelay: '0.2s'}}></div>
                    <div className="w-2 h-2 bg-blue-600 rounded-full animate-bounce" style={{animationDelay: '0.4s'}}></div>
                </div>
            </div>
        </div>
    );
}

export default LoadingScreen;